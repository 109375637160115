.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Footer {
  margin-top: auto;
}

/* .social-button {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
} */
